@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.infoTooltip {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    cursor: help;
    pointer-events: auto;

    button,
    a > & {
        cursor: inherit;
    }

    // HOVERs
    &:hover .infoTooltipIcon {
        color: inherit;
    }

    &--grid {
        display: grid;
        grid-template-columns: 20fr 1fr;
    }
}
.infoTooltipIcon {
    display: inline-flex;
    flex-shrink: 0;
    margin-left: 0.5rem;
    font-size: 1rem;
    transition: color $transition;
    &:first-child {
        margin-left: 0;
    }

    :global(label) & {
        margin-top: -0.25rem;
        margin-bottom: -0.25rem;
    }
}
