@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.header {
	--bs-gutter-x: 2.5rem;
	position: sticky;
	top: 0;
	z-index: $zindex-offcanvas;

	// VARIANTs
	&--bd .headerHolder {
		border-bottom: 1px solid $grey-medium;
	}

	// MQs
	@media ($mdDown) {
		// VARIANTs
		&--bd-mobile .headerHolder {
			border-bottom: 1px solid $grey-medium;
		}
	}
	@media ($mdUp) {
		z-index: calc($zindex-sticky + 1);
	}
}
.headerHolder {
	display: flex;
	align-items: center;
	min-height: 4.5rem;
	padding: 1.25rem 0;

	// MQs
	@media ($mdUp) {
		min-height: 6.5rem;
		padding: 1.375rem 0;
	}
}
.headerLogo {
	flex-shrink: 0;
	width: 7rem;
	a {
		display: block;
	}

	// MQs
	@media (min-width: 389.98px) {
		width: 9rem;
	}
	@media ($mdUp) {
		width: 10.5625rem;
	}
}
.headerNav {
	position: relative;
	margin-top: -0.625rem;
	margin-bottom: -0.625rem;
	margin-left: 1.5rem;
	padding-left: calc(0.75rem + 2px);
	line-height: divide(26, 18);
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 2px;
		height: 1.25rem;
		margin-top: -0.625rem;
		background-color: $grey-light;
	}
	:global(.nav-item) {
		position: relative;
		display: flex;
		align-items: center;
	}
	:global(.nav-link) {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		max-height: 3.75rem;
		padding-top: 0.4375rem;
		padding-bottom: 0.4375rem;
		&::before {
			@include transition(opacity $transition);
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 1.25rem;
			height: 2px;
			margin-left: -0.625rem;
			background-color: $anthracite-grey;
			opacity: 0;
		}

		&:global(.active),
		&:hover {
			&::before {
				opacity: 1;
			}
		}
	}

	// MQs
	@media ($xxxlDown) {
		:global(.nav-link) {
			padding-right: 0.75rem;
			padding-left: 0.75rem;
		}
	}
	@media ($xlDown) {
		display: none;
	}
	@media ($xxxlUp) {
		margin-left: 2.5rem;
		padding-left: calc(1.25rem + 2px);
	}
}
.headerNavDropdown {
	top: 100%;
	z-index: $zindex-dropdown;
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y $dropdown-padding-x;
	visibility: hidden;
	opacity: 0;
	transition: opacity $transition, visibility $transition;
	box-shadow: $dropdown-box-shadow;
	:global(.nav) {
		display: block;
	}
	:global(.nav-item) {
		margin: 0 ($dropdown-padding-x * -1);
	}
	:global(.nav-link) {
		width: 100%;
		padding: $dropdown-item-padding-y $dropdown-item-padding-x;
		color: $dropdown-link-color;
		font-size: $dropdown-font-size;
		line-height: divide(24, 16);
		&::before {
			display: none;
		}

		// HOVERs
		&:hover,
		&:focus {
			color: $dropdown-link-hover-color;
		}

		// STATEs
		&.active,
		&:active {
			color: $dropdown-link-active-color;
		}
	}
	.headerNavDropdown {
		top: ($dropdown-padding-y * -1);
		left: 100%;
	}

	// STATEs
	&:focus-within,
	:global(.nav-link):focus + &,
	:global(.nav-item):hover > & {
		visibility: visible;
		opacity: 1;
	}

	// MQs
	@media ($xxxlDown) {
		left: 0.75rem - $dropdown-item-padding-x;
	}
	@media ($xxxlUp) {
		left: calc(1.25rem + 2px - $dropdown-item-padding-x);
	}
}
.headerNavNotification {
	position: absolute;
	top: 0.1875rem;
	left: 50%;
	width: 0.5rem;
	height: 0.5rem;
	margin-left: -0.25rem;
	border-radius: 50%;
}
.headerToolbar {
	:global(.btn-toolbar) {
		margin-left: -0.5rem;
	}
	:global(.btn-group) {
		margin-left: 0.5rem;
	}

	// VARIANTs
	&--left {
		padding-right: 1rem;
	}
	&--right {
		margin-left: auto;
		padding-left: 1rem;

		// MQs
		@media ($mdUp) {
			padding-left: 1.25rem;
		}
		@media ($xxxlUp) {
			padding-left: 2.5rem;
		}
		@media ($xlUp) {
			padding-left: 0.75rem;
		}
	}

	// MQs
	@media ($mdUp) {
		:global(.btn-toolbar) {
			margin-left: -0.625rem;
		}
		:global(.btn-group) {
			margin-left: 0.625rem;
		}
	}
	@media ($xxxlUp) {
		:global(.btn-group) {
			&.headerCta,
			&.headerCta :global(+ .btn-group) {
				margin-left: 1.25rem;
			}
		}
	}
}
.headerButton {
	// MQs
	@media ($mdDown) {
		padding: 0.3125rem 0.9375rem;
		border-radius: $border-radius-xs;
		line-height: 1;
		:global(.icon) {
			font-size: (1rem * divide(20, 24));
		}
		:global(.avatar) {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
.headerCta {
	// MQs
	@media ($mdDown) {
		display: none;
	}
}
