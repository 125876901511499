@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
$tabs-nav-button-padding-x: 0.625rem;

.tabsNav {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5rem;
    margin-right: -$tabs-nav-button-padding-x;
    margin-left: -$tabs-nav-button-padding-x;

    // VARIANTs
    &--center {
        justify-content: center;
    }
    &--lg {
        margin-right: -1rem;
        margin-left: -1rem;
    }
    &--pricelistv2 {
        justify-content: space-around;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }
    &--buttons {
        gap: 0.5rem;
        max-width: 500px;
        margin-inline: auto;
    }
}
.tabsNavButton {
    position: relative;
    display: flex;
    margin-top: 0.5rem;
    padding: 0.1875rem $tabs-nav-button-padding-x;
    color: $anthracite-grey;
    text-decoration: none;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: $tabs-nav-button-padding-x;
        left: $tabs-nav-button-padding-x;
        height: 1px;
        background-color: currentColor;
        transition: opacity $transition;
    }

    // VARIANTs
    &--green::after {
        background-color: $emerald-green;
    }
    &--blue::after {
        background-color: $royal-blue;
    }
    .tabsNav--lg & {
        padding: 1rem;
        &::after {
            right: 1rem;
            left: 1rem;
        }
    }
    .tabsNav--pricelist & {
        &::after {
            height: 2px;
        }
        &:global(:not(.active)) {
            color: $anthracite-grey;
        }
    }
    .tabsNav--pricelistv2 & {
        font-size: 1.375rem;
        margin-top: 0;
        &:after {
            background: $anthracite-grey;
        }
        &:global(:not(.active)) {
            color: $grey-dark;
        }
    }
    .tabsNav--buttons & {
        @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
        @include transition($btn-transition);
        display: inline-block;
        vertical-align: middle;
        border: $btn-border-width solid transparent;
        background-color: transparent;
        color: $body-color;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        line-height: $btn-line-height;
        letter-spacing: $letter-spacing;
        white-space: $btn-white-space;
        text-align: center;
        text-transform: uppercase;
        text-decoration: if($link-decoration == none, null, none);
        cursor: if($enable-button-pointers, pointer, null);
        user-select: none;
        flex: 1;

        &:global(:is(.active)) {
            $data: map_get($btn-variants, 'secondary');
            @include button-variant(
                $background: map_get($data, 'background'),
                $border: map_get($data, 'border'),
                $color: map_get($data, 'color'),
                $hover-background: map_get($data, 'hover-background'),
                $hover-border: map_get($data, 'hover-border'),
                $hover-color: map_get($data, 'hover-color'),
                $active-background: map_get($data, 'active-background'),
                $active-color: map_get($data, 'active-color'),
                $disabled-color: map_get($data, 'color')
            );
        }
        &:after {
            display: none;
        }

        &:global(:not(.active)) {
            border-color: $grey-medium;
            color: $grey-dark;
            &:hover {
                border-color: $grey-medium;
                background-color: $grey-medium;
                color: $black;
            }
            &:focus {
                box-shadow: 0 0 0 0.25rem rgba(204, 190, 188, 0.5);
                &:not(:focus-visible) {
                    box-shadow: none;
                }
            }
        }
    }
    // HOVERs
    &:hover {
        color: $anthracite-grey;
    }

    // STATEs
    &:global(:not(.active)) {
        color: $grey-medium;
        &::after {
            opacity: 0;
        }

        // HOVERs
        &:hover {
            color: $anthracite-grey;
        }
    }
}
