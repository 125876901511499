@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.menuSidebar {
	overflow-y: auto;
	:global(.vstack) {
		min-height: 100%;
	}
	:global(.nav) {
		margin: -0.6875rem -1rem;
	}
    h6 {
        font-size: .875rem;
    }
	:global(.nav-link) {
		padding: 0.5rem 1rem;
		color: $anthracite-grey;
		font-size: 1.125rem;
		line-height: divide(28, 22);
        font-weight: $font-weight-light;
	}
	:global(.nav-link .badge) {
		vertical-align: top;
		margin-top: -0.125rem;
		margin-bottom: -0.125rem;
	}
	:global(.nav .nav) {
		margin: 0 1rem;
	}
	:global(.nav .nav .nav-link) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 1.125rem;
		line-height: divide(24, 18);
	}
	:global(.accordion-button .icon) {
		transition: transform $transition;
	}

	// HOVERs
	:global(.nav-link:hover) {
		color: $link-hover-color;
	}

	// STATEs
	:global(.accordion-button[aria-expanded='true'] .icon) {
		transform: scaleY(-1);
	}

	// MQs
	@media ($mdUp) {
		:global(.nav) {
			margin-right: -2.5rem;
			margin-left: -2.5rem;
		}
		:global(.nav-link) {
			padding-right: 2.5rem;
			padding-left: 2.5rem;
		}
		:global(.nav .nav) {
			margin-right: 2.5rem;
			margin-left: 2.5rem;
		}
	}
}
.menuSidebarHeader {
	margin-bottom: 1.25rem;

	// MQs
	@media ($mdUp) {
		margin-top: -0.375rem;
		margin-bottom: 1.625rem;
	}
}
.menuSidebarFooter {
	margin-top: auto;
	:global(.btn-toolbar) {
		margin-right: -0.625rem;
		margin-bottom: -1rem;
		margin-left: -0.625rem;
	}
	:global(.btn-group) {
		flex: 1 0 0%;
		margin-bottom: 1rem;
		padding-right: 0.625rem;
		padding-left: 0.625rem;
	}
	:global(.btn) {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-weight: $font-weight-medium;
		letter-spacing: normal;
		text-transform: none;
	}
	:global(.btn:not([class*='langSwitcherLabel'])) {
		font-size: 1.125rem;
		line-height: divide(24, 18);
	}
	:global(.langSwitcher) {
		width: 100%;
	}

	// MQs
	@media ($mdDown) {
		:global(.btn-group) {
			flex-basis: 100%;
		}
	}
}
