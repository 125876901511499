@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.contactBox {
	padding: 2rem 1.75rem;
	border: 1px solid $grey-medium;
	border-radius: $border-radius-sm;

	// MQs
	@media ($xxlUp) {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}
.contactBoxHeading,
.contactBoxLink {
	padding-left: 4.5rem;
}
.contactBoxHeading {
	color: $anthracite-grey;
}
.contactBoxItem {
	margin-top: 2rem;
	margin-bottom: 0;
	padding-top: 2rem;
	border-top: 1px solid $grey-medium;
}
.contactBoxLink {
	position: relative;
	display: flex;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
}
.contactBoxLinkIcon {
	&:global(.icon) {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		color: $grey-dark;
		font-size: 1rem;
	}
}
.contactBoxLinkDesc {
	display: block;
	padding-top: 0.375rem;
	color: $grey-dark;
}
