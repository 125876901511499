body {
	overflow-y: scroll;
}
a {
	transition: color $transition;
}
th,
td {
	padding: 0;
}
* {
    overflow-wrap: break-word;
}
h1, h2, h3, h4, h5, h6 {
    &[id]:before {
        content: '';
        display: block;
        height: var(--header-height, 0);
        margin-top: calc(var(--header-height, 0) * -1);
        visibility: hidden;
    }
}
