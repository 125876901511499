@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.avatar {
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: $border-radius;
    background-color: $grey-light;

    // VARIANTs
    &--xs {
        width: 28px;
        height: 28px;
        border-radius: $border-radius-xs;
    }
    &--sm {
        width: 40px;
        height: 40px;
    }
    &--md {
        width: 52px;
        height: 52px;
    }
    &--lmd {
        width: 80px;
        height: 80px;
    }
    &--lg {
        width: 120px;
        height: 120px;
    }
    &--xl {
        width: 180px;
        height: 180px;
    }
    &--circle {
        border-radius: 50%;
    }
    &--cursor {
        cursor: pointer;
    }
    :global(.btn) & {
        margin: -0.125rem -0.875rem;
    }
}
