:focus {
	outline-width: 0.25rem;
	outline-color: $input-btn-focus-color;
}

/* stylelint-disable */
.h1 {
	@extend h1;
}
.h2 {
	@extend h2;
}
.h3 {
	@extend h3;
}
.h4 {
	@extend h4;
}
.h5 {
	@extend h5;
}
.h6 {
	@extend h6;
}
/* stylelint-enable */

h1,
.h1 {
	line-height: $h1-line-height;

	@media ($lgDown) {
		font-size: $h1-font-size-mobile;
		line-height: $h1-line-height-mobile;
	}
}
h2,
.h2 {
	line-height: $h2-line-height;

	@media ($lgDown) {
		font-size: $h2-font-size-mobile;
		line-height: $h2-line-height-mobile;
	}
}
h3,
.h3 {
	line-height: $h3-line-height;

	@media ($lgDown) {
		font-size: $h3-font-size-mobile;
		line-height: $h3-line-height-mobile;
	}
}
h4,
.h4 {
	font-weight: $font-weight-normal;
	line-height: $h4-line-height;

	@media ($lgDown) {
		font-size: $h4-font-size-mobile;
		line-height: $h4-line-height-mobile;
	}
}
h5,
.h5 {
	font-weight: $font-weight-semibold;
	line-height: $h5-line-height;

	@media ($lgDown) {
		font-size: $h5-font-size-mobile;
		line-height: $h5-line-height-mobile;
	}
}
h6,
.h6 {
	font-weight: $font-weight-semibold;
	line-height: $h6-line-height;

	@media ($lgDown) {
		font-size: $h6-font-size-mobile;
		line-height: $h6-line-height-mobile;
	}
}

.text-title {
	font-weight: $font-weight-lighter;
	font-size: $title-font-size;
	line-height: $title-line-height;

	@media ($lgDown) {
		font-size: $title-font-size-mobile;
		line-height: $title-line-height-mobile;
	}
}
.text-subheadline {
	@include text-subheadline();
}
.text-subheadline-lg {
	font-weight: $font-weight-normal;
	font-size: $subheadline-lg-font-size;
	line-height: $subheadline-lg-line-height;

	@media ($lgDown) {
		font-size: $subheadline-lg-font-size-mobile;
		line-height: $subheadline-lg-line-height-mobile;
	}
}
.text-perex {
	font-weight: $font-weight-normal;
	font-size: $perex-font-size;
	line-height: $perex-line-height;
}
.text-perex-lg {
	font-weight: $font-weight-normal;
	font-size: $perex-lg-font-size;
	line-height: $perex-lg-line-height;

	@media ($lgDown) {
		font-size: $perex-font-size;
		line-height: $perex-line-height;
	}
}
.text-body {
	@include text-body();
}
.text-caption {
	font-weight: $font-weight-normal;
	font-size: $caption-font-size;
	line-height: $caption-line-height;

	@media ($lgDown) {
		font-size: $caption-font-size-mobile;
		line-height: $caption-line-height-mobile;
	}
}
.text-link-perex {
	font-weight: $font-weight-normal;
	font-size: $link-perex-font-size;
	line-height: $link-perex-line-height;
}
.text-cta {
	font-weight: $font-weight-semibold;
	font-size: $cta-font-size;
	line-height: $cta-line-height;
	letter-spacing: $letter-spacing;
	text-transform: uppercase;
}
.text-link {
	text-decoration: underline;
	&:hover {
		color: $link-hover-color;
		text-decoration: none;
	}
}
.text-small {
    font-size: $small-font-size;
    line-height: $line-height-sm;
}
.text-xs {
    font-size: $xs-font-size;
    line-height: $xs-line-height;
}
