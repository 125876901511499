.form-control {
	&::placeholder {
		transition: opacity $transition;
	}

	&:focus::placeholder {
		opacity: 0.5;
	}

	&:disabled,
	&[readonly] {
		opacity: 0.5;
	}

	@at-root textarea#{&} {
		@include scrollbar();
		&::-webkit-scrollbar-track {
			margin: 0.25rem 0;
		}
	}

	// hide arrows on any number input #20214
    &[type="number"] {
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
    }
}

.form-control-sm {
	line-height: $input-line-height-sm;
}

.form-control-lg {
	line-height: $input-line-height-lg;
}

.form-control-plaintext {
	font-size: 1.125rem;
}
