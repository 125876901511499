.pagination {
	@include list-unstyled();
	display: flex;
	margin: 0 -0.25rem;
}

.page-link {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	transition: background-color $transition, color $transition;

	// HOVERs
	@at-root a#{&}:hover {
		background-color: rgba($anthracite-grey, 0.1);
		color: $primary;
	}
}

.page-item {
	margin: 0 0.25rem;

	// STATEs
	&.active .page-link {
		background-color: $primary;
		color: $white;
	}
	&.disabled .page-link {
		opacity: 0.5;
		pointer-events: none;
	}
}
