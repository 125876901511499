.form-select {
	&:not(:disabled) {
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.form-select-sm {
	padding-right: $form-select-indicator-padding-sm;
	background-position: $form-select-bg-position-sm;
	line-height: $form-select-line-height-sm;
}

.form-select-lg {
	padding-right: $form-select-indicator-padding-lg;
	background-position: $form-select-bg-position-lg;
	line-height: $form-select-line-height-lg;
}
