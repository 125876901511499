.input-group-text {
	> .form-select {
		width: auto;
		margin: calc(-#{$input-group-addon-padding-y} - #{$form-select-border-width})
			calc(-#{$input-group-addon-padding-x} - #{$form-select-border-width});
		border-radius: inherit;

		&:focus {
			z-index: 1;
		}
	}

	// VARIANTs
	&--required {
		> span {
			margin-top: 0.25rem;
            color: $danger;
		}
	}
}
.input-group > .form-select,
.input-group > .form-control,
.input-group > .rmsc {
	+ .input-group-text {
		margin-left: 0 !important; /* stylelint-disable-line */
		padding-left: 0;
		border-left: 0;
		transition: border-color $transition;
	}

	&:focus + .input-group-text {
		border-color: $input-focus-border-color;
	}
}
.input-group {
	> .rmsc {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
	}

	&:not(.has-validation) {
		> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
		> .dropdown-toggle:nth-last-child(n + 3) {
			&,
			.dropdown-container {
				@include border-end-radius(0);
				border-right: 0;
			}
		}
	}

	&.has-validation {
		> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
		> .dropdown-toggle:nth-last-child(n + 4) {
			&,
			.dropdown-container {
				@include border-end-radius(0);
				border-right: 0;
			}
		}
	}
}
