@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.form-check {
    // VARIANTs
    &--control-on-right {
        position: relative;
        padding-right: $form-check-padding-start;
        padding-left: 0;
        :global(.form-check-input) {
            float: right;
            margin-right: $form-check-padding-start * -1;
            margin-left: 0;
        }
        // :global(.form-check-label) {
        //     &::before {
        //         content: '';
        //         position: absolute;
        //         inset: 0;
        //     }
        // }

        &:global(.form-switch) {
            padding-right: $form-switch-padding-start;
            :global(.form-check-input) {
                margin-right: $form-switch-padding-start * -1;
            }
        }
    }
    &--standalone {
        padding-left: 0;
        :global(.form-check-input) {
            margin-left: 0;
        }
    }
    &--person {
        :global(.form-check-label) {
            margin-top: 0;
        }
        :global(.form-check-input) {
            margin-top: calc((3.25rem - #{$form-check-min-height}) / 2);
        }
    }
    &--highlighted {
        :global(.form-check-input) {
            border-color: $grey-ultra-dark;
        }
    }
}
