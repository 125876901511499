.breadcrumb {
	margin-left: -$breadcrumb-item-padding-x;
	font-weight: $font-weight-semibold;
	line-height: divide(16, 14);
	letter-spacing: $letter-spacing;
	text-transform: uppercase;

	// MQs
	@media ($mdDown) {
		font-size: 0.8125rem;
	}
}

.breadcrumb-item {
	@include text-truncate();
	margin-bottom: 0.25rem;
	padding-left: $breadcrumb-item-padding-x;
	& + &.active::before {
		color: $grey-dark;
	}

	// MQs
	@media ($mdDown) {
		&.d-none + &::before {
			content: '\002026';
		}
	}
}
