.container,
.container-fluid {
	max-width: $container-max-width;
	.container {
		--bs-gutter-x: 0;
	}

	@media ($fullDown) {
		--bs-gutter-x: #{$container-padding-x-tablet};
	}
	@media ($mdDown) {
		--bs-gutter-x: #{$container-padding-x-mobile};
	}
}
