@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.spinner {
	position: relative;

	// VARIANTs
	&--overlay,
	&--fullpage {
		background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
	}
	&--overlay {
		z-index: 1;
	}
	&--fullpage {
		z-index: $zindex-spinner;
	}
}
.spinnerIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
