@mixin text-truncate-multiple($lines: false) {
	display: -webkit-box;
	text-overflow: ellipsis;
	overflow: hidden;
	/* autoprefixer: ignore next */
	-webkit-box-orient: vertical;
	@if $lines {
		-webkit-line-clamp: $lines;
	}
}
