@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.box {
	// VARIANTs
	&--scrollable {
		@include scrollbar();
	}
	&--scrollable-horizontal {
		@include scrollbarHorizontal();
	}
	&--rounded-sm {
		border-radius: $border-radius-sm;
	}
	&--rounded-lg {
		border-radius: $border-radius-lg;
	}
	&--rounded-xl {
		border-radius: $border-radius-xl;
	}
}
