.toast {
	.btn-close {
		margin: ($toast-padding-y * -0.5) ($toast-padding-x * -0.5) ($toast-padding-y * -0.5) 0.375rem;
		padding: 0;
	}

	&.bg-success,
	&.bg-danger {
		.toast-body {
			color: $white;
		}
	}

    &-container {
        z-index: 11000;
    }
}
