@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.langSwitcher {
	position: relative;
	display: inline-flex;
	vertical-align: top;
	color: $grey-dark;
	cursor: pointer;

	// HOVERs
	&:hover {
		color: $link-hover-color;
	}
}
.langSwitcherLabel {
	display: flex;
	justify-content: center;
	font-size: 0.875rem;
	line-height: divide(20, 14);
	transition: $btn-transition;

	// VARIANTs
	&--button {
		font-weight: $font-weight-medium;
		font-size: 1rem;
		line-height: divide(24, 16);
		letter-spacing: normal;
		text-transform: none;

        &--small > label {
            margin-left: -0.75rem;
            margin-right: -0.75rem;
        }
	}
	&:not(#{&--button}) ~ .langSwitcherSelect select {
		width: calc(100% + 2 * 0.5rem);
		height: calc(100% + 2 * 0.5rem);
		margin: -0.5rem 0 0 -0.5rem;
	}

	// HOVERs
	.langSwitcher:hover &--button {
		background-color: map-get($btn-outline-variants, 'dark', 'color');
		color: $body-color;
	}

	// MQs
	@media ($mdDown) {
		&:global(:not(.btn)) {
			padding-top: 0.125rem;
			padding-bottom: 0.125rem;
		}
	}
	@media ($mdUp) {
		font-size: 1rem;
		line-height: divide(24, 16);
	}
}
.langSwitcherIcon {
	margin-right: 0.75rem;

	// MQs
	@media ($mdDown) {
		:global(.icon) {
			font-size: (1rem * divide(20, 24));
		}
	}
}
.langSwitcherSelect {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	select {
		width: 100%;
		height: 100%;
		padding: 0;
		border: 0;
		border-radius: $btn-border-radius;
		background-color: transparent;
		opacity: 0;
		transition: $btn-transition;
		cursor: inherit;
		appearance: none;
	}

	// STATEs
	.langSwitcherLabel--button ~ & select:focus {
		outline: 0;
		box-shadow: 0 0 0 $btn-focus-width rgba(map-get($btn-outline-variants, 'dark', 'color'), 0.5);
	}
}
