@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.image {
    position: relative;
	display: inline-block;
	vertical-align: top;
	span {
		vertical-align: top;
	}
	video {
		max-width: 100%;
		height: 100%;
	}

    &-caption {
        display: inline-block;
        color: white;
        font-size: 0.9rem;
        padding: 0.5rem;
        background: rgba(0,0,0,0.3);
        border-radius: 1rem;
        backdrop-filter: blur(2px);
        margin: auto;
        max-width: 90%;

        &-small {
            font-size: 0.65rem;
            padding: 0.4rem;
        }

        &--wrapper {
            position: absolute;
            width: 100%;
            bottom: 0.75rem;
            left: 0;
        }
    }
}

.imageTags {
    @media($mdUp) {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(50%);
    }
    padding: 0 1.125rem;

    > *:not(:last-child) {
        margin-right: 0.5rem;
    }
}
