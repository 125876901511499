@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.socialsMenu {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -1rem;
	margin-left: -2rem;
	color: $grey-dark;
}
.socialsMenuLink {
	display: flex;
	margin-bottom: 1rem;
	margin-left: 2rem;
	a {
		position: relative;
		color: inherit;
		&::before {
			content: '';
			position: absolute;
			top: -0.625rem;
			right: -0.625rem;
			bottom: -0.625rem;
			left: -0.625rem;
		}
	}

	// HOVERs
	&--facebook:hover {
		color: $facebook;
	}
	&--instagram:hover {
		color: $instagram;
	}
	&--twitter:hover {
		color: $twitter;
	}
}
