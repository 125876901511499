.tooltip-xmd {
	// MQs
	@media ($smUp) {
		.tooltip-inner {
			max-width: 340px;
		}
	}
}

.tooltip-md {
	// MQs
	@media ($smUp) {
		.tooltip-inner {
			max-width: 400px;
		}
	}
}
