$modal-header-height: $subheadline-font-size * $subheadline-line-height + $modal-header-padding-y * 2;

.modal {
    --#{$prefix}modal-bg: #ffffff;
}

.modal-header {
	position: relative;
	min-height: calc($modal-header-height + $modal-header-border-width);
	.btn-close {
		position: absolute;
		top: ($modal-header-height - ($btn-close-width + 2 * $btn-close-padding-x)) * 0.5;
		right: ($modal-header-padding-x - ($btn-close-width + 2 * $btn-close-padding-x)) * 0.5;
		margin: 0;
		padding: $btn-close-padding-x;
	}
	// VARIANTs
	&--no-bd {
		min-height: $modal-header-height;
		border-bottom: 0;
	}
	&--no-bd ~ .modal-body {
		margin-top: $modal-header-padding-y * -0.5;
		padding-top: 0;
	}
}

.modal-title {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.modal-body {
	// MQs
	@media ($smDown) {
		padding: 1rem;
	}
}

.modal-content {
	box-shadow: $box-shadow-custom;
}

.modal-footer {
	padding: ($modal-footer-padding-y - $modal-footer-margin-between * 0.5) ($modal-footer-padding-x - $modal-footer-margin-between * 0.5);
}

.modal-onboarding {
	// MQs
	@media ($mdDown) {
		width: 100%;
		max-width: 100%;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border-radius: 0;
		}
	}
}

.modal ~ .modal-backdrop {
	z-index: $zindex-modal;
}
