@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.tag {
    display: inline-block;
    vertical-align: top;
}
.tagInput {
    @include visually-hidden();

    // STATEs
    &:checked ~ .tagLabel {
        border-color: currentColor;
        color: $anthracite-grey;
    }
    &:checked:hover ~ .tagLabel::before {
        opacity: 1;
    }
    &:not(:checked):hover ~ .tagLabel {
        border-color: $grey-dark;
    }
    &:disabled {
        pointer-events: none;
    }
    &[disabled],
    &:disabled {
        ~ .tagLabel {
            opacity: $form-check-label-disabled-opacity;
        }
    }
    &:not(:disabled) ~ .tagLabel {
        cursor: pointer;
    }
}
.tagLabel {
    position: relative;
    padding: 0.5rem 0.9375rem;
    border: 1px solid $grey-medium;
    border-radius: $border-radius-sm;
    color: $grey-dark;
    font-size: 1rem;
    line-height: divide(22, 16);
    white-space: nowrap;
    transition: color $transition, border-color $transition;
    &::before {
        content: '';
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        border-radius: inherit;
        background-color: rgba($white, 0.7);
        background-image: escape-svg($form-tag-close);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
        opacity: 0;
        transition: opacity $transition;
    }
}

.tagLabelSmall {
    padding: 0.3rem 0.7375rem;
    font-size: 0.8rem;
}
