.form-floating {
	position: relative;
	border-top: ($form-label-line-height * $form-label-font-size + $form-label-margin-bottom) solid transparent;

	> label {
		@include transition($form-floating-transition);
		position: absolute;
		top: 0;
		left: 0;
		margin-bottom: 0;
		padding: $input-padding-y $input-padding-x;
		border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
		font-weight: $input-font-weight;
		font-size: $input-font-size;
		line-height: $input-line-height;
		letter-spacing: normal;
		text-transform: none;
		transform-origin: 0 0;
		pointer-events: none;
	}

	> .form-control {
		&::placeholder {
			color: transparent;
		}
	}

	> .form-control-sm ~ label {
		padding: $input-padding-y-sm $input-padding-x-sm;
		font-size: $input-font-size-sm;
		line-height: $input-line-height-sm;
	}

	> .form-control-lg ~ label {
		padding: $input-padding-y-lg $input-padding-x-lg;
		font-size: $input-font-size-lg;
		line-height: $input-line-height-lg;
	}

	> .form-control:focus,
	> .form-control:not(:placeholder-shown),
	> .form-select {
		~ label {
			@include text-truncate();
			max-width: percentage(divide(1, $form-floating-label-ratio));
			font-weight: $form-label-font-weight;
			letter-spacing: (divide(1, $form-floating-label-ratio) * $letter-spacing);
			text-transform: uppercase;
			transform: $form-floating-label-transform;
		}
	}
	// Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
	> .form-control:-webkit-autofill {
		~ label {
			@include text-truncate();
			max-width: percentage(divide(1, $form-floating-label-ratio));
			font-weight: $form-label-font-weight;
			letter-spacing: (divide(1, $form-floating-label-ratio) * $letter-spacing);
			text-transform: uppercase;
			transform: $form-floating-label-transform;
		}
	}

	> .form-control-sm:focus,
	> .form-control-sm:not(:placeholder-shown),
	> .form-select-sm {
		~ label {
			max-width: percentage(divide(1, $form-floating-label-ratio-sm));
			letter-spacing: (divide(1, $form-floating-label-ratio-sm) * $letter-spacing);
			transform: $form-floating-label-transform-sm;
		}
	}
	// Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
	> .form-control-sm:-webkit-autofill {
		~ label {
			max-width: percentage(divide(1, $form-floating-label-ratio-sm));
			letter-spacing: (divide(1, $form-floating-label-ratio-sm) * $letter-spacing);
			transform: $form-floating-label-transform-sm;
		}
	}

	> .form-control-lg:focus,
	> .form-control-lg:not(:placeholder-shown),
	> .form-select-lg {
		~ label {
			max-width: percentage(divide(1, $form-floating-label-ratio-lg));
			letter-spacing: (divide(1, $form-floating-label-ratio-lg) * $letter-spacing);
			transform: $form-floating-label-transform-lg;
		}
	}
	// Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
	> .form-control-lg:-webkit-autofill {
		~ label {
			max-width: percentage(divide(1, $form-floating-label-ratio-lg));
			letter-spacing: (divide(1, $form-floating-label-ratio-lg) * $letter-spacing);
			transform: $form-floating-label-transform-lg;
		}
	}
}
