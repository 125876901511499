.btn-close {
	transition: opacity $transition;

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}

.alert-dismissible .btn-close {
    width: 1rem;
    height: 1rem;
}
