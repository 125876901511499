@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.calendarSelectionGroup {
	:global(> *) {
		flex-grow: 1;
	}
	:global(> :first-child:not(:only-child)) {
		:global(.form-control),
		:global(.form-select) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&:not(:focus) {
				border-right-color: transparent;
			}
		}
	}
	:global(> :last-child:not(:only-child)) {
		:global(.form-control),
		:global(.form-select) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:not(:focus) {
				border-left-color: transparent;
			}
		}
	}
	:global(.form-control) {
		text-transform: capitalize;
	}
	:global(.form-control),
	:global(.form-select) {
		text-align: center;
	}
	:global(.form-select) {
		padding-right: $form-select-padding-x;
		background-image: none;
	}

    :global(.form-select.is-invalid) {
        background-size: calc(0.6666666667em + 1.0625rem) calc(0.6666666667em + 1.0625rem) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23cc0000' d='M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm.004 1.499l-.248.005c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.99-8.003l-.252-.004zm-.005 11.501l.117.007a1 1 0 11-.226-.001l.109-.006zM12 6.75a.75.75 0 01.743.648l.007.102v5a.75.75 0 01-1.493.102l-.007-.102v-5a.75.75 0 01.75-.75z'/%3e%3c/svg%3e") !important;
    }

	// MQs
	@media ($smDown) {
		:global(.form-control),
		:global(.form-select) {
			padding-right: 0.6875rem;
			padding-left: 0.6875rem;
		}
	}
}
.calendarSelectionGroupSeparator {
	border: solid $grey-medium;
	border-width: 1px 0;
}
