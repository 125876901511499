@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.datepickerInput[readonly] {
	opacity: 1;
}
.datepickerRangeInput {
    text-align: center;
    text-transform: capitalize;
}
.datepickerRoot {
	user-select: none;
}
.datepickerButtonReset {
	padding: 0;
	border: 0;
	background: none;
	appearance: none;
}
.datepickerCaption {
	position: relative;
	margin-bottom: 0.5rem;
	padding: 0.375rem 6rem 0.375rem 0;
	font-weight: $font-weight-normal;
	font-size: $subheadline-font-size;
	line-height: $subheadline-line-height;
	text-transform: capitalize;
}
.datepickerMonths {
	display: flex;
	//flex-wrap: wrap;
	justify-content: center;
    gap: 1rem;
}
.datepickerMonth {
	flex: 1 1 auto;
	user-select: none;
}
.datepickerNav {
	position: absolute;
	top: 0;
	right: 0;
}
.datepickerTable {
	width: (7 * 2.25rem);
	margin: 0;

	// MQs
	@media ($xxsUp) {
		width: (7 * 2.5rem);
	}
}
.datepickerHead {
	color: $grey-medium;
	text-transform: capitalize;
}
.datepickerHeadCell {
	font-weight: $font-weight-normal;
}
.datepickerHeadCell,
.datepickerCell {
	width: 2.25rem;
	height: 2.25rem;
	text-align: center;

	// MQs
	@media ($xxsUp) {
		width: 2.5rem;
		height: 2.5rem;
	}
}
.datepickerDay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
	border-radius: 50%;
	background-clip: padding-box;
	transition: background-color $transition, color $transition;
	cursor: pointer;
	&:not(.datepickerDayDisabled):not(.datepickerDaySelected):not(.datepickerDayOutside):hover {
		background-color: rgba($royal-blue, 0.75);
		color: $white;
	}
    &:global(.rdp-day_range_start):not(:global(.rdp-day_range_end)) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:global(.rdp-day_range_end):not(:global(.rdp-day_range_start)) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &:global(.rdp-day_range_middle) {
        border-radius: 0;
    }
}
.datepickerDayToday {
	color: $primary;
	font-weight: $font-weight-medium;
}
.datepickerDayOutside {
	color: $grey-light;
	cursor: default;
}
.datepickerDayDisabled {
	color: $grey-light;
	cursor: not-allowed;
}
.datepickerDaySelected:not(.datepickerDayDisabled):not(.datepickerDayOutside) {
	position: relative;
	background-color: $royal-blue;
	color: $white;
}

// VARIANTs
.datepickerDialog {
	position: absolute;
	z-index: $zindex-fixed;
	.datepickerHolder {
		margin-top: 0.5rem;
		border-radius: $border-radius-sm;
		background: $white;
		box-shadow: $box-shadow-custom;
	}
	.datepickerRoot {
		display: inline-block;
		vertical-align: top;
		padding: 1rem;
	}
}
.datepickerMultipleMonths {
	// MQs
	@media ($lgDown) {
		.datepickerMonth ~ .datepickerMonth {
			display: none;
		}
	}
	@media ($lgUp) {
		.datepickerMonths {
			margin-left: -2.5rem;
		}
		.datepickerMonth {
			margin-left: 2.5rem;
			&:not(:last-child) {
				.datepickerCaption {
					padding-right: 0;
				}
				.datepickerNav {
					display: none;
				}
			}
		}
	}
}
