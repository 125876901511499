@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.usp {
    display: flex;
}

.uspIcon {
    width: 1.8rem;
    margin-right: 0.5rem;
}
.uspText {
    margin-bottom: 0.5rem;
    max-width: 90%;
}
