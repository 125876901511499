.progress {
	border: solid transparent;
	border-width: 0.0625rem 0;
	background-clip: content-box;
	overflow: visible;
}
.progress-bar {
	margin-top: -0.0625rem;
	margin-bottom: -0.0625rem;
}
