@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.rangeSlider {
    height: 2rem;

    // STATEs
    &:global(.disabled) {
        opacity: 0.5;
        .rangeSliderThumb {
            cursor: default;
        }
    }
}
.rangeSliderTrack {
    position: relative;
    top: 0.9375rem;
    height: 0.125rem;
    border-radius: 0.0625rem;
    background-color: $grey-ultra-light;

    // VARIANTs

    .onGrey & {
        background: $white;
    }
    &--active,
    .onGrey &--active {
        background-color: $primary;
    }
}
.rangeSliderThumb {
    position: relative;
    top: 0;
    width: 2rem;
    height: 2rem;
    border: 1px solid $grey-medium;
    border-radius: 50%;
    background-color: $grey-ultra-light;
    transition: background-color $transition, border-color $transition;
    cursor: pointer;
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 1px;
        height: 0.75rem;
        margin-top: -0.375rem;
        background-color: $grey-medium;
    }
    &::before {
        left: 50%;
        margin-left: -3px;
    }
    &::after {
        right: 50%;
        margin-right: -3px;
    }
    &:focus {
        outline: none;
    }
    .onGrey & {
        background: $white;
    }
}
.rangeSliderThumbActive {
    border-color: $primary;
    background-color: $white;
}
.rangeSliderLabels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.875rem;

    > * {
        flex: 1;
        text-align: center;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
}
