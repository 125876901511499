@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.divider {
	margin: 1rem 0;

	// VARIANTs
	&--text {
		display: flex;
		align-items: center;
		hr {
			flex-grow: 1;
			margin: 0 0 0 2.5rem;
		}
	}
}
