@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.sidebar {
    // MQs
    @media ($mdDown) {
        // VARIANTs
        &--messages,
        &--menu,
        &--login {
            top: var(--header-height);
        }
        &--overlay {
            height: 100%;
        }
        &--dashboard {
            transition: none;
        }
    }

    @media ($mdUp) {
        &--menu {
            width: 30rem;
        }
    }
}
