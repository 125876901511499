@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.multiSelect {
    --rmsc-main: #{$anthracite-grey} !important;
    --rmsc-hover: #{$grey-ultra-light} !important;
    --rmsc-selected: #{$grey-light} !important;
    --rmsc-border: #{$grey-medium} !important;
    --rmsc-gray: #{$grey-medium} !important;
    --rmsc-bg: #{$white} !important;
    --rmsc-p: 1.4375rem !important; /* Spacing */
    --rmsc-radius: 0.75rem !important; /* Radius */
    --rmsc-h: 3.625rem !important; /* Height */
    // z-index: 100;
    // position: relative;

    :global(.dropdown-heading) {
        gap: 1rem;
        cursor: pointer;
        :global(.icon) {
            margin-right: -0.4375rem;
        }
    }

    :global(.dropdown-container) {
        &:focus-within,
        &[aria-disabled='true']:focus-within {
            box-shadow: none;
        }
    }

    :global(.dropdown-content) {
        z-index: 100;
        :global(.panel-content) {
            border: 1px solid var(--rmsc-border);
            box-shadow: 0 4px 11px #0000001a;
        }
    }

    :global(.select-item) {
        padding: 0.75rem 1rem;

        &:has(.separator):hover {
            background: none;
            cursor: default;
        }
    }

    :global(.item-renderer) {
        align-items: center;
    }

    // VARIANTS
    &--sm {
        --rmsc-p: 0.9375rem !important; /* Spacing */
        --rmsc-h: 2.375rem !important; /* Height */

        :global(.dropdown-heading) {
            gap: 0.5rem;
        }
    }
    &--content-lg {
        :global(.dropdown-content) {
            width: 200%;
            :global(.options) {
                max-height: 448px;
            }
        }
    }
    &--content-left {
        :global(.dropdown-content) {
            right: auto;
            left: 0;
        }
    }
    &--content-right {
        :global(.dropdown-content) {
            right: 0;
            left: auto;
        }
    }

    // STATEs
    &--disabled {
        :global(.dropdown-heading) {
            cursor: default;
        }

        :global(.dropdown-container) {
            opacity: 0.5;
        }
    }
    &:global(.is-valid) {
        --rmsc-main: #{$success};
        --rmsc-border: #{$success};
    }
    &:global(.is-invalid) {
        --rmsc-main: #{$danger};
        --rmsc-border: #{$danger};
    }
}
.separator {
    height: 1px;
    background: var(--rmsc-selected);
}
