// The dropdown wrapper (`<div>`)
.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

// The dropdown menu
.dropdown-menu {
	position: absolute;
	z-index: $zindex-dropdown;
	display: none; // none by default, but block on "open" of the menu
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y $dropdown-padding-x;
	border-radius: $dropdown-border-radius;
	background-clip: padding-box;
	background-color: $dropdown-bg;
	color: $dropdown-color;
	font-size: $dropdown-font-size;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	box-shadow: $dropdown-box-shadow;

	&[data-bs-popper] {
		top: 100%;
		left: 0;
		margin-top: $dropdown-spacer;
	}
}

// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.dropdown-menu#{$infix}-start {
			--bs-position: start;

			&[data-bs-popper] {
				right: auto;
				left: 0;
			}
		}

		.dropdown-menu#{$infix}-end {
			--bs-position: end;

			&[data-bs-popper] {
				right: 0;
				left: auto;
			}
		}
	}
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
	.dropdown-menu[data-bs-popper] {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: $dropdown-spacer;
	}
}

.dropend {
	.dropdown-menu[data-bs-popper] {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: $dropdown-spacer;
	}
}

.dropstart {
	.dropdown-menu[data-bs-popper] {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: $dropdown-spacer;
	}
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
	height: 0;
	margin: $dropdown-divider-margin-y 0;
	border-top: 1px solid $dropdown-divider-bg;
	overflow: hidden;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
	display: block;
	clear: both;
	width: calc(100% + (2 * $dropdown-padding-x)); // For `<button>`s
	margin: 0 ($dropdown-padding-x * -1);
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	border: 0; // For `<button>`s
	background-color: transparent; // For `<button>`s
	color: $dropdown-link-color;
	font-weight: $font-weight-normal;
	white-space: nowrap; // prevent links from randomly breaking onto new lines
	text-align: inherit; // For `<button>`s

	&:hover,
	&:focus {
		color: $dropdown-link-hover-color;
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		pointer-events: none;
	}

	// Prevent dropdown overflow if there's no padding
	@if $dropdown-padding-y == 0 {
		&:first-child {
			@include border-top-radius($dropdown-inner-border-radius);
		}

		&:last-child {
			@include border-bottom-radius($dropdown-inner-border-radius);
		}
	}
}

.dropdown-menu.show {
	display: block;
}
