:root {
	--header-height: 72px;
	--viewport-height: 100vh;
	--element-scrollbar-width: 0.75rem;

	// MQs
	@media ($mdUp) {
		--header-height: 104px;
	}
}
