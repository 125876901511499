//
// Alternate alerts
//

@each $color, $data in $alert-variants {
	.alert-#{$color} {
		@include alert-variant(
			$background: map_get($data, 'background'),
			$border: map_get($data, 'border'),
			$color: map_get($data, 'color'),
		);
	}
}