@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.container {
    // VARIANTs
    &--thin {
        max-width: calc($container-thin-max-width + 2 * var(--bs-gutter-x, $container-padding-x));
    }
    &--medium {
        max-width: calc($container-medium-max-width + 2 * var(--bs-gutter-x, $container-padding-x));
    }
    &--narrower {
        max-width: calc($container-narrower-max-width + 2 * var(--bs-gutter-x, $container-padding-x));
    }
    &--narrow {
        max-width: calc($container-narrow-max-width + 2 * var(--bs-gutter-x, $container-padding-x));
    }
    &--large {
        max-width: $container-large-max-width;
    }
    &--wide {
        // MQs
        @media ($mdDown) {
            --bs-gutter-x: 0;
        }
        @media ($fullUp) {
            --bs-gutter-x: 5.5rem;
        }
    }
    &--md-wide {
        @media ($fullUp) {
            --bs-gutter-x: 1.5rem;
        }
    }
}
