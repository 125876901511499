@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.badge {
	min-width: 2 * $badge-padding-x + $badge-font-size;

	// VARIANTs
	&--lg {
		min-width: 2 * 0.4375rem + 1.125rem;
		padding-top: 0.4375rem;
		padding-bottom: 0.4375rem;
		border-radius: $border-radius;
		font-size: 1.125rem;
	}
}
