.table {
	border-width: 1px;
	border-style: solid;
	> thead > tr > th {
		background-color: $emerald-green;
		color: $white;
	}
	> :not(:first-child) {
		border-top: 0;
	}
}

.table-responsive {
	@include scrollbar();
	@include scrollbarHorizontal(false, 1);
}

$table-breakpoints: (
	sm: $smDown,
	md: $mdDown,
	lg: $lgDown,
	xl: $xlDown,
	xxl: $xxlDown
);

@each $breakpoint in map-keys($table-breakpoints) {
	$infix: breakpoint-infix($breakpoint, $table-breakpoints);

	.table-responsive#{$infix} {
		@include scrollbar();
		@include scrollbarHorizontal(map_get($table-breakpoints, $breakpoint), 1);
	}
}
