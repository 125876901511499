@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
@media (max-width: 379.98px) {
  .watchdogsButton {
    display: none;
  }

  .messagesButton {
    display: none;
  }

  .notificationsButton {
    display: none;
  }
}