@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.footer {
    color: $grey-dark;
}
.footerHolder {
    // margin-top: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 1.875rem;
    // border-top: 1px solid $grey-medium;

    // MQs
    @media ($mdUp) {
        padding-top: 4rem;
    }
}
.footerSideContent {
    // MQs
    @media ($mdUp) {
        height: 100%;
        :global(> .vstack) {
            height: 100%;
        }
    }
    @media ($lgUp) {
        width: 20rem;
    }
    @media ($xxlUp) {
        width: 22rem;
    }
    @media ($fullUp) {
        width: 26rem;
    }
}
.footerLogo,
.footerClaim {
    // MQs
    @media ($mdUp) {
        margin-bottom: 1.5rem;
    }
}
.footerLogo {
    margin-bottom: 1.25rem;

    // MQs
    @media ($mdDown) {
        :global(.logo) {
            max-width: 8.125rem;
        }
    }
}
.footerClaim {
    margin-bottom: 3.25rem;
}
.footerNav {
    margin-top: 2rem;
    :global(.nav-link) {
        padding: 0.3125rem 0;
        color: $anthracite-grey;
        font-weight: $font-weight-semibold;
        font-size: 0.875rem;
        line-height: divide(22, 14);
        text-transform: uppercase;
    }

    // HOVERs
    :global(.nav-link:hover) {
        color: $link-hover-color;
    }

    // MQs
    @media ($mdDown) {
        margin-bottom: 2.1875rem;
        :global(.accordion-body) {
            margin-top: -0.5rem;
            margin-bottom: 0.5625rem;
        }
    }
    @media ($mdUp) {
        margin-top: 3.125rem;
        :global(.accordion-collapse) {
            /* stylelint-disable */
            display: block !important;
            height: auto !important;
            /* stylelint-enable */
        }
    }
    @media ($xxxlUp) {
        :global(.nav) {
            width: 12rem;
        }
    }
}
.footerNavTitle {
    margin-bottom: 0.25rem;
    font-weight: $font-weight-normal;
    :global(.accordion-header) {
        display: block;
    }
    :global(.accordion-button) {
        width: 100%;
        padding: 0;
        border: 0;
        background: none;
        color: inherit;
        text-align: left;
        appearance: none;
    }

    // MQs
    @media ($mdDown) {
        padding-top: 0.875rem;
        border-top: 1px solid $grey-medium;
        :global(.accordion) & {
            margin-bottom: 0;
            padding-top: 0;
        }
        :global(.accordion-button) {
            position: relative;
            padding: 0.75rem 2.125rem 0.75rem 0;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0.3125rem;
                width: 1.5rem;
                height: 1.5rem;
                margin-top: -0.75rem;
                background-image: escape-svg($footer-accordion-button-svg);
                transition: transform $transition;
            }
            &[aria-expanded='true']::before {
                transform: scaleY(-1);
            }
        }
    }
    @media ($mdUp) {
        :global(.accordion-button) {
            cursor: default;
        }
    }
}
.footerSocials {
    margin-top: auto;
}
.footerBottom {
    margin-top: 1.875rem;
    font-weight: $font-weight-medium;

    // MQs
    @media ($mdUp) {
        padding-top: 1.5rem;
        border-top: 1px solid $grey-medium;
    }
    @media ($xxlUp) {
        margin-top: 5rem;
    }
}
.footerCopyright {
    //margin: -1rem -1rem -1rem 0;
    padding: 1rem 1rem 1rem 0;
    overflow: hidden;

    // MQs
    @media ($mdDown) {
        font-size: 0.875rem;
        line-height: divide(24, 14);
    }
}
.footerCopyrightHolder {
    margin-bottom: -0.5rem;
    margin-left: -1.5rem;
}
.footerCopyrightItem {
    position: relative;
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
    &::before {
        content: '·';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -0.875rem;
        width: 0.25rem;
        text-align: center;
    }

    // VARIANTs
    &--year,
    &--lang-switcher {
        &::before {
            display: none;
        }
    }

    // MQs
    @media ($mdDown) {
        &::before {
            display: none;
        }

        // VARIANTs
        &--link + &--link::before {
            display: block;
        }
        &--copy {
            order: 1;
        }
        &--year,
        &--lang-switcher {
            order: 2;
        }
    }
    @media ($mdUp) {
        // VARIANTs
        &--year,
        &--lang-switcher {
            margin-left: auto;
        }
    }
}
