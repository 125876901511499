@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.limitedTextarea {
    position: relative;
    border: 1px solid $grey-medium;
    border-radius: 0.75rem;
    transition: border-color 0.2s ease-in-out;
    background-color: white;

    textarea {
        border: none;
        resize: none;
        @media ($mdUp) {
            padding-bottom: $input-padding-y-lg;
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }
}

.limitedTextareaCount, .limitedTextareaLanguage {
    pointer-events: none;
}

.limitedTextareaButton {
    pointer-events: cursor;
}

.limitedTextareaBottom {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
