@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.infoText {
	display: flex;
}
.infoTextIcon {
	flex-shrink: 0;
	align-self: center;
}
.infoTextContent {
	flex-grow: 1;
	padding-top: 0.1875rem;
}
