@mixin scrollbar() {
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $anthracite-grey $grey-ultra-light;
    &::-webkit-scrollbar {
        width: 0.75rem;
        height: 0.75rem;
    }
    &::-webkit-scrollbar-track {
        border: 0.25rem solid transparent;
        border-radius: 0.375rem;
        background: $grey-ultra-light;
        background-clip: padding-box;
    }
    &::-webkit-scrollbar-thumb {
        border: 0.25rem solid transparent;
        border-radius: 0.375rem;
        background: $anthracite-grey;
        background-clip: padding-box;
    }
}

@mixin scrollbarHorizontalBase($padding-modificator: 0.5) {
    margin-right: calc(-1 * var(--bs-gutter-x));
    margin-left: calc(-1 * var(--bs-gutter-x));
    padding-right: calc(#{$padding-modificator} * var(--bs-gutter-x));
    padding-left: calc(#{$padding-modificator} * var(--bs-gutter-x));
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar-track {
        margin-right: calc(var(--bs-gutter-x) * 1 - 0.25rem);
        margin-left: calc(var(--bs-gutter-x) * 1 - 0.25rem);
        background-color: $white;
    }
}

@mixin scrollbarHorizontal($breakpoint: $mdDown, $padding-modificator: 0.5) {
    @if $breakpoint {
        @media ($breakpoint) {
            @include scrollbarHorizontalBase($padding-modificator);
        }
    } @else {
        @include scrollbarHorizontalBase($padding-modificator);
    }
}

@mixin offsetScrollbar($padding) {
    margin-right: calc(#{divide($padding, -2)} - var(--element-scrollbar-width, 0px) / 2);
    margin-left: divide($padding, -2);
    padding-right: calc(#{divide($padding, 2)} - var(--element-scrollbar-width, 0px) / 2);
    padding-left: divide($padding, 2);
}
