//
// Base styles
//

.btn {
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
	@include transition($btn-transition);
	display: inline-block;
	vertical-align: middle;
	border: $btn-border-width solid transparent;
	background-color: transparent;
	color: $body-color;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	letter-spacing: $letter-spacing;
	white-space: $btn-white-space;
	text-align: center;
	text-transform: uppercase;
	text-decoration: if($link-decoration == none, null, none);
	cursor: if($enable-button-pointers, pointer, null);
	user-select: none;

	.text-cta {
		display: inline-block;
		vertical-align: top;
		padding: 0.25rem 0;
	}

	&:hover {
		color: $body-color;
		text-decoration: if($link-hover-decoration == underline, none, null);
	}

	.btn-check:focus + &,
	&:focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	.btn-check:focus:not(:focus-visible) + &,
	&:focus:not(:focus-visible) {
		box-shadow: none;
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:active,
	&.active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}

	&:disabled,
	&.disabled,
	fieldset:disabled & {
		@include box-shadow(none);
		opacity: $btn-disabled-opacity;
		pointer-events: none;

        &[title] {
            pointer-events: initial;
            cursor: default;
        }
	}

	// MQs
	@media ($lgDown) {
		padding-right: $input-btn-padding-x-sm;
		padding-left: $input-btn-padding-x-sm;
		font-size: $btn-font-size-mobile;
		line-height: divide(24, 14);
	}
}

//
// Alternate buttons
//

@each $color, $data in $btn-variants {
	.btn-#{$color} {
		@include button-variant(
			$background: map_get($data, 'background'),
			$border: map_get($data, 'border'),
			$color: map_get($data, 'color'),
			$hover-background: map_get($data, 'hover-background'),
			$hover-border: map_get($data, 'hover-border'),
			$hover-color: map_get($data, 'hover-color'),
			$active-background: map_get($data, 'active-background'),
			$active-color: map_get($data, 'active-color'),
			$disabled-color: map_get($data, 'color')
		);
	}
}

@each $color, $data in $btn-variants {
	.btn-outline-#{$color} {
		@include button-outline-variant(
			$color: map_get($data, 'background'),
			$color-hover: map_get($data, 'color'),
			$active-color: map_get($data, 'active-color')
		);
	}
}
@each $color, $data in $btn-outline-variants {
	.btn-outline-#{$color} {
		@include button-outline-variant(
			$color: map_get($data, 'color'),
			$color-hover: map_get($data, 'color-hover'),
			$active-color: map_get($data, 'active-color')
		);
	}
}

.btn-outline-primary-light,
.btn-outline-secondary-light {
	color: $white;

	&:disabled,
	&.disabled {
		color: $white;
	}
}
.btn-outline-dark {
	color: $grey-dark;

	&:disabled,
	&.disabled {
		color: $grey-dark;
	}
}
.btn-outline-medium {
	color: $anthracite-grey;

	&:disabled,
	&.disabled {
		color: $anthracite-grey;
	}
}
.btn-outline-secondary {
	.btn-check + & {
		border-color: $grey-medium;
		color: $anthracite-grey;
		.text-perex,
		.text-grey-dark {
			transition: color $transition;
		}
	}

	.btn-check + &:hover,
	.btn-check:checked + &,
	.btn-check:active + & {
		border-color: $royal-blue;
		color: $white;
		.text-perex,
		.text-grey-dark {
			color: rgba($white, 0.8) !important; /* stylelint-disable-line */
		}
	}
}

//
// Button Sizes
//

.btn-lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
	.btn-check + & {
		font-weight: $font-weight-normal;
		text-transform: none;
	}

	// MQs
	@media ($lgDown) {
		padding: $btn-padding-y $input-btn-padding-x-sm;
		line-height: divide(24, 16);
	}
	@media ($lgUp) {
		.btn-check + & {
			padding-top: $input-padding-y-lg;
			padding-bottom: $input-padding-y-lg;
			font-size: $input-font-size-lg;
			line-height: $input-line-height-lg;
		}
	}
}

.btn-md {
	@include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $btn-border-radius-md);
	line-height: divide(24, 14);

	// MQs
	@media ($mdDown) {
		font-size: 0.8125rem;
		line-height: divide(24, 13);
	}
}

.btn-sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
	font-weight: $font-weight-normal;
	letter-spacing: normal;
	text-transform: none;

	// MQs
	@media ($lgDown) {
		line-height: divide(24, 16);
	}
}

.btn-narrow {
	padding-right: 0.9375rem;
	padding-left: 0.9375rem;
}
.btn-wide {
	// MQs
	@media ($mdUp) {
		padding-right: 2.4375rem;
		padding-left: 2.4375rem;
	}
}
.btn-side-to-side {
	display: inline-flex;
	justify-content: space-between;
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link,
.btn-link-light,
.btn-link-grey,
.btn-link-grey-dark {
	padding: $btn-link-padding 0;
	color: $btn-link-color;
	font-size: $btn-link-font-size;
	line-height: $btn-link-line-height;
	text-align: left;

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus {
		text-decoration: $link-hover-decoration;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
	}

	&.btn-lg {
		padding: 0.4375rem 0;
	}
}

.btn-link-light {
	color: $white;

	.bg-green &:hover {
		color: $white;
		text-decoration: underline;
	}
}

.btn-link-grey {
	color: $grey-dark;
}

.btn-link-grey-dark {
	color: $anthracite-grey;
}

.btn-icon {
	&:hover {
		color: $btn-link-hover-color;
	}
}

.btn-blank {
	padding: 0;
	border: 0;
	color: inherit;
	font-weight: $font-weight-normal;
	font-size: $font-size-base;
	line-height: divide(22, 16);
	letter-spacing: normal;
	text-transform: none;
	text-decoration: underline;
	&:not(:disabled):hover {
		color: $link-hover-color;
		text-decoration: none;
	}
}

.btn-circle {
    border-radius: 50%;
    background-color: white;
    border-color: transparent;

    &.btn-sm {
        width: 2.5rem;
        height: 2.5rem;

        padding: 0.5rem 0;
    }

    &:disabled {
        background-color: transparentize(white, 0.3);
    }
}
