.btn-group {
	> .btn:not(:last-child):not(.dropdown-toggle),
	> .btn-group:not(:last-child) > .btn {
		border-right-color: transparent;
	}

	> .btn:nth-child(n + 3),
	> :not(.btn-check) + .btn,
	> .btn-group:not(:first-child) > .btn {
		border-left-color: transparent;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: -1px;
			width: 1px;
			height: 0.625rem;
			margin-top: -0.3125rem;
		}

		&.btn-outline-dark::before {
			background-color: $grey-medium;
		}
		&.btn-lg::before {
			height: 1.25rem;
			margin-top: -0.625rem;
		}
	}

    &.vertically-separate {
        &::before {
            content: '';
            margin-right: 0.625rem;
            border-left: 1px solid $grey-medium;
        }
    }
}
