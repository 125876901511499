@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.icon {
    display: inline-flex;
    vertical-align: top;
    width: 1.5em;
    height: 1.5em;
    svg {
        width: 100%;
        height: 100%;
        fill: currentcolor;
    }

    // VARIANTs
    &--xs {
        width: 1em;
        height: 1em;
    }
    &--sm {
        width: 1.25em;
        height: 1.25em;
    }
    &--md {
        width: 1.75em;
        height: 1.75em;
    }
    &--lg {
        width: 2em;
        height: 2em;
    }
    &--xl {
        width: 2.5em;
        height: 2.5em;
    }
    &--xxl {
        width: 3em;
        height: 3em;
    }
    a & {
        align-self: center;
        &--start {
            margin-right: 0.75rem;
        }
        &--end {
            margin-left: 0.75rem;
        }
        &--middle {
            margin-right: -0.375rem;
            margin-left: -0.375rem;
            &.icon--lg {
                margin-right: -0.625rem;
                margin-left: -0.625rem;
            }
        }
        &--md {
            margin-top: -0.125rem;
            margin-bottom: -0.125rem;
        }
        &--lg {
            margin-top: -0.25rem;
            margin-bottom: -0.25rem;
        }

        // MQs
        @media ($lgDown) {
            &--middle {
                margin-right: -0.125rem;
                margin-left: -0.125rem;
                &.icon--lg {
                    margin-right: -0.375rem;
                    margin-left: -0.375rem;
                }
            }
        }
    }
    :global(.btn) & {
        flex-shrink: 0;
        align-self: center;
        font-size: 1rem;
        &--start {
            margin-right: 0.75rem;
        }
        &--end {
            margin-left: 0.75rem;
        }
        &--middle {
            margin-right: -0.375rem;
            margin-left: -0.375rem;
            &.icon--lg {
                margin-right: -0.625rem;
                margin-left: -0.625rem;
            }
        }
        &--md {
            margin-top: -0.125rem;
            margin-bottom: -0.125rem;
        }
        &--lg {
            margin-top: -0.25rem;
            margin-bottom: -0.25rem;
        }

        // MQs
        @media ($lgDown) {
            &--middle {
                margin-right: -0.125rem;
                margin-left: -0.125rem;
                &.icon--lg {
                    margin-right: -0.375rem;
                    margin-left: -0.375rem;
                }
            }
        }
    }
    :global(.btn-sm) & {
        &--start {
            margin-left: -0.25rem;
        }
        &--end {
            margin-right: -0.25rem;
        }
        &--middle {
            margin-right: -0.75rem;
            margin-left: -0.75rem;
            &.icon--md {
                margin-right: -1rem;
                margin-left: -1rem;
            }
            &.icon--lg {
                margin-right: -1rem;
                margin-left: -1rem;
            }
        }
    }
    :global(.btn-md) & {
        margin-top: -0.125rem;
        margin-bottom: -0.125rem;
        font-size: (1rem * divide(28, 24));
        &--start {
            margin-left: -0.25rem;
        }
        &--end {
            margin-right: -0.25rem;
        }
        &--middle {
            margin-right: -0.625rem;
            margin-left: -0.625rem;
        }
    }
    :global(.btn-lg) & {
        &--middle {
            margin-right: 0;
            margin-left: 0;
            &.icon--lg {
                margin-right: -0.25rem;
                margin-left: -0.25rem;
            }
        }

        // MQs
        @media ($lgDown) {
            &--middle {
                margin-right: -0.125rem;
                margin-left: -0.125rem;
                &.icon--lg {
                    margin-right: -0.375rem;
                    margin-left: -0.375rem;
                }
            }
        }
    }
    :global(.btn-narrow) & {
        &--start {
            margin-right: 0.4375rem;
            margin-left: -0.4375rem;
        }
        &--end {
            margin-right: -0.4375rem;
            margin-left: 0.4375rem;
        }
    }
    :global(.btn-separator) & {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0.125rem;
            bottom: 0.125rem;
            width: 1px;
            background-color: $grey-light;
        }
        &--start {
            margin-right: 1.5rem;
            margin-left: -0.75rem;
            &::after {
                right: -0.5rem;
            }
        }
        &--end {
            margin-right: -0.75rem;
            margin-left: 1.5rem;
            &::after {
                left: -0.5rem;
            }
        }
    }

    :global(.btn-link) &,
    :global(.btn-link-light) &,
    :global(.btn-link-grey) &,
    :global(.btn-link-grey-dark) & {
        margin-top: -0.25rem;
        margin-bottom: -0.25rem;
        &--start {
            margin-right: 0.875rem;
            margin-left: -0.0625rem;
        }
        &--end {
            margin-right: -0.0625rem;
            margin-left: 0.875rem;
        }
        &--middle {
            margin-right: -0.0625rem;
            margin-left: -0.0625rem;
        }
        &--sm {
            margin-top: -0.125rem;
            margin-bottom: -0.125rem;
        }
        &--md {
            margin-top: -0.375rem;
            margin-bottom: -0.375rem;
        }
        &--lg {
            margin-top: -0.5rem;
            margin-bottom: -0.5rem;
        }
    }

    :global(.input-group-text) & {
        font-size: 1rem;
    }
}
