// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
	.row {
		@include make-row();

		> * {
			@include make-col-ready();
		}
	}

	.row--12 {
		@include make-grid-columns($grid-columns-mobile, $grid-gutter-width, map-remove($grid-breakpoints, 'xs', 'sm'));
	}
}

@if $enable-cssgrid {
	.grid {
		@include make-cssgrid();
		display: grid;
		grid-template-columns: repeat(var(--#{$variable-prefix}columns, #{$grid-columns}), 1fr);
		grid-template-rows: repeat(var(--#{$variable-prefix}rows, 1), 1fr);
		gap: var(--#{$variable-prefix}gap, #{$grid-gutter-width});
	}
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
	@include make-grid-columns($grid-columns-mobile, $grid-gutter-width-mobile, map-remove($grid-breakpoints, 'md', 'lg', 'xl', 'xxl'));
	@include make-grid-columns($grid-columns, $grid-gutter-width, map-remove($grid-breakpoints, 'xs', 'sm'));
}

.col,
.col-auto {
	min-width: 0;
}
