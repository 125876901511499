@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.select {
	// VARIANTs
	&--blank {
		padding: 0 1.375rem 0 0;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		background-image: escape-svg($form-select-indicator-blank);
		background-position: right center;
		color: $anthracite-grey;
		&:focus {
			box-shadow: $btn-focus-box-shadow;
		}
	}
}
