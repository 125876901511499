.offcanvas {
    @include transition(transform $offcanvas-transition-duration ease-in-out);
    position: fixed;
    bottom: 0;
    z-index: $zindex-offcanvas;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    visibility: hidden;
    box-sizing: content-box;
    .modal ~ &,
    & ~ .modal-backdrop {
        z-index: $zindex-modal;
    }
}

.offcanvas-backdrop {
    @include overlay-backdrop($zindex-offcanvas-backdrop, $offcanvas-backdrop-bg, $offcanvas-backdrop-opacity);
    .modal ~ & {
        z-index: $zindex-modal;
    }
}

.offcanvas-start,
.offcanvas-end {
    top: 0;
    width: 100%;

    // MQs
    @media ($mdUp) {
        width: $offcanvas-horizontal-width;
    }
}

.offcanvas-start {
    left: 0;
    transform: translateX(-100%);
}

.offcanvas-end {
    right: 0;
    transform: translateX(100%);
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    //height: 100%;
    transform: translateY(100%);
}

.offcanvas.show {
    transform: none;
}
