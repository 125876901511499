@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.contentBox {
	padding: 1rem;
	background-clip: padding-box;
	transition: border-color $transition, box-shadow $transition;

	// VARIANTs
	&--outline-grey-medium {
		border: 1px solid $grey-medium;
	}
	&--outline-grey-light {
		border: 1px solid $grey-light;
	}
	&--shadow {
		box-shadow: $box-shadow-custom;
	}
	a > &--outline-grey-medium,
	a > &--outline-grey-light {
		transition: border-color $transition;
	}

	// HOVERs
	a > &--outline-grey-medium:hover,
	a > &--outline-grey-light:hover {
		border-color: $grey-dark;
	}
	:global(.btn-check) + &--outline-grey-medium:hover,
	:global(.btn-check) + &--outline-grey-light:hover {
		border-color: $royal-blue;
	}

	// STATEs
	&--outline-grey-medium#{&}--selected,
	&--outline-grey-light#{&}--selected {
		border-color: $royal-blue;
		box-shadow: inset 0 0 0 1px $royal-blue;
	}
}
