@mixin text-subheadline() {
	font-weight: $font-weight-normal;
	font-size: $subheadline-font-size;
	line-height: $subheadline-line-height;

	@media ($lgDown) {
		font-size: $subheadline-font-size-mobile;
		line-height: $subheadline-line-height-mobile;
	}
}

@mixin text-body() {
	font-weight: $font-weight-normal;
	font-size: $body-font-size;
	line-height: $body-line-height;
}
