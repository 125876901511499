.form-check-input {
    margin-top: 0;

    &:focus:not(:focus-visible) {
        border-color: $form-check-input-checked-border-color;
        box-shadow: none;
    }

    &:hover:not(:disabled) {
        border-color: $form-check-input-checked-border-color;
        cursor: $form-check-label-cursor;
    }

    &:active:not(:disabled) {
        border-color: $form-check-input-checked-border-color;
    }
    &:active[type='checkbox']:not(:checked):not(:disabled) {
        background-color: $form-check-input-checked-border-color;
    }
    &:active[type='radio']:not(:checked):not(:disabled) {
        border-width: 0.25em;
        background-color: $form-check-input-checked-color;
    }
}

.form-check-label {
    max-width: 100%;
    margin-top: divide($form-check-input-width - $form-check-font-size * $form-check-line-height, divide($form-check-font-size, 1em)) * 0.5;
    font-size: $form-check-font-size;
    line-height: $form-check-line-height;
    cursor: default;
    .form-check-input:not(:disabled) ~ & {
        cursor: $form-check-label-cursor;
    }
}

.form-switch {
    min-height: $form-switch-height;
    .form-check-input {
        height: $form-switch-height;
        border-color: $grey-light;
        background-color: $grey-light;

        &:active:not(:checked):not(:disabled) {
            background-color: $grey-light;
        }
    }
    .form-check-label {
        margin-top: divide($form-switch-height - $form-check-font-size * $form-check-line-height, divide($form-check-font-size, 1em)) * 0.5;
    }
}

.form-check-xs {
    min-height: $form-check-min-height-xs;
    padding-left: $form-check-padding-start-xs;
    .form-check-input {
        width: $form-check-input-width-xs;
        height: $form-check-input-width-xs;
        margin-left: $form-check-padding-start-xs * -1;
    }
    .form-check-label {
        margin-top: divide(
                $form-check-input-width-xs - $form-check-font-size-xs * $form-check-line-height-xs,
                divide($form-check-font-size-xs, 1em)
            ) * 0.5;
        font-weight: $font-weight-normal;
        font-size: $form-check-font-size-xs;
        line-height: $form-check-line-height-xs;
    }
}

.form-check-sm {
    min-height: $form-check-min-height-sm;
    padding-left: $form-check-padding-start-sm;
    .form-check-input {
        width: $form-check-input-width-sm;
        height: $form-check-input-width-sm;
        margin-left: $form-check-padding-start-sm * -1;
    }
    .form-check-label {
        margin-top: divide(
                $form-check-input-width-sm - $form-check-font-size-sm * $form-check-line-height-sm,
                divide($form-check-font-size-sm, 1em)
            ) * 0.5;
        font-weight: $font-weight-semibold;
        font-size: $form-check-font-size-sm;
        line-height: $form-check-line-height-sm;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
    }
}
