@import "~@styles/variables.scss";@import "~@styles/common/index.scss";
.logos {
    padding-left: 0;
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    margin: auto;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    li {
        list-style-type: none;
    }
}
